<template>
  <div class="content">
    <md-dialog-alert
      :md-active.sync="noBuddie"
      :md-content="getContent(1296)"
      :md-confirm-text="getContent(1295)"
    />

    <md-dialog :md-active.sync="challengePopUp" v-if="!myWidth">
      <md-dialog-content style="margin: 20px; overflow: auto">
        <div class="challengeHeader">
          <div class="challengeTitle" v-if="selectedDay == 999">
            {{
              user.challenges[selectedChallenge]
                ? user.challenges[selectedChallenge].challenge.title
                : ""
            }}
          </div>
          <div class="challengeTitle" v-else>
            Day {{ selectedDay + 1 }} -
            {{
              user.challenges[selectedChallenge] &&
              user.challenges[selectedChallenge].challenge
                ? user.challenges[selectedChallenge].challenge.activity[
                    selectedDay
                  ].title
                : ""
            }}
          </div>
          <button
            class="challengeButton"
            v-if="
              user.challenges[selectedChallenge] &&
              selectedDay ==
                user.challenges[selectedChallenge].activitiesCompleted
            "
            @click="
              activityCompleted(),
                selectedDay + 1 !=
                user.challenges[selectedChallenge].challenge.activity.length
                  ? selectedDay++
                  : _
            "
          >
            <md-icon>check_box_outline_blank</md-icon> Mark as completed
          </button>
          <div
            v-else-if="
              user.challenges[selectedChallenge] &&
              selectedDay <
                user.challenges[selectedChallenge].activitiesCompleted
            "
            style="display: flex; flex-direction: column"
          >
            <button class="challengeButtonCompleted">
              <md-icon>check_box</md-icon> Completed
            </button>
            <button
              class="challengeButtonRestart"
              style="margin-top: 5%"
              @click="restartChallenge(), (selectedDay = 0)"
            >
              <md-icon>restart_alt</md-icon> Restart Challenge
            </button>
          </div>
        </div>
        <div class="challengeImage" @click="selectedDay = 999">
          {{
            user.challenges[selectedChallenge]
              ? user.challenges[selectedChallenge].challenge.title
              : ""
          }}
        </div>
        <div class="flexRow">
          <div
            class="challengeWrapper"
            v-if="user.challenges[selectedChallenge]"
          >
            <div
              v-if="
                user.challenges[selectedChallenge] &&
                user.challenges[selectedChallenge].challenge &&
                user.challenges[selectedChallenge].challenge.activity
              "
            >
              <div
                v-for="(activity, i) in user.challenges[selectedChallenge]
                  .challenge.activity"
                v-bind:key="i"
                :class="
                  selectedDay == i
                    ? 'challengeActivitySelected'
                    : 'challengeActivity'
                "
                @click="selectedDay = i"
              >
                <md-icon
                  v-if="
                    user.challenges[selectedChallenge] &&
                    user.challenges[selectedChallenge].activitiesCompleted <= i
                  "
                  style="margin: 0; margin-right: 5px"
                  >check_box_outline_blank</md-icon
                >
                <md-icon v-else style="margin: 0; margin-right: 5px"
                  >check_box</md-icon
                >
                Day {{ i + 1 }} - {{ activity.title }}
              </div>
            </div>
            <div class="author">
              <div class="flexRow">
                <img
                  :src="user.challenges[selectedChallenge].challenge.author.img"
                  v-if="user.challenges[selectedChallenge].challenge.author.img"
                />
                <div class="authorName">
                  Author:<br />{{
                    user.challenges[selectedChallenge].challenge.author.name
                  }}
                </div>
              </div>
              <div
                class="academicDescription"
                v-if="
                  user.challenges[selectedChallenge].challenge.author
                    .academicDescription
                "
              >
                {{
                  user.challenges[selectedChallenge].challenge.author
                    .academicDescription
                }}
              </div>
            </div>
          </div>
          <div class="challengeText" v-if="selectedDay == 999">
            <vue-markdown>
              {{
                user.challenges[selectedChallenge]
                  ? user.challenges[selectedChallenge].challenge.text
                  : ""
              }}
            </vue-markdown>
          </div>
          <div class="challengeText" v-else>
            <div
              v-if="
                user.challenges[selectedChallenge] &&
                user.challenges[selectedChallenge].challenge.activity
              "
            >
              <div
                v-bind:key="i"
                v-for="(a, i) in user.challenges[selectedChallenge].challenge
                  .activity"
              >
                <vue-markdown v-if="i === selectedDay">{{
                  user.challenges[selectedChallenge].challenge.activity[
                    selectedDay
                  ].text
                }}</vue-markdown>
              </div>
            </div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="challengePopUp = false"
          style="font-size: 1.3em; margin: 0 20px 20px 0"
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="challengePopUp" v-if="myWidth">
      <md-dialog-content style="margin: 20px; overflow: auto">
        <div class="challengeHeaderMobile">
          <div class="challengeTitle" v-if="selectedDay == 999">
            {{ user.challenges[selectedChallenge].challenge.title }}
          </div>
          <div class="challengeTitle" v-else>
            Day {{ selectedDay + 1 }} -
            {{
              user.challenges[selectedChallenge].challenge.activity[selectedDay]
                .title
            }}
          </div>
          <button
            class="challengeButton"
            v-if="
              selectedDay ==
              user.challenges[selectedChallenge].activitiesCompleted
            "
            @click="
              activityCompleted(),
                selectedDay + 1 !=
                user.challenges[selectedChallenge].challenge.activity.length
                  ? selectedDay++
                  : _
            "
          >
            <md-icon>check_box_outline_blank</md-icon> Mark as completed
          </button>
          <div
            v-else-if="
              selectedDay <
              user.challenges[selectedChallenge].activitiesCompleted
            "
            style="display: flex; flex-direction: column"
          >
            <button class="challengeButtonCompleted">
              <md-icon>check_box</md-icon> Completed
            </button>
            <button
              class="challengeButtonRestart"
              style="margin-top: 5%"
              @click="restartChallenge(), (selectedDay = 0)"
            >
              <md-icon>restart_alt</md-icon> Restart Challenge
            </button>
          </div>
        </div>
        <div class="challengeImage" @click="selectedDay = 999">
          {{ user.challenges[selectedChallenge].challenge.title }}
        </div>
        <div class="flexColumn">
          <div class="challengeWrapperMobile">
            <div
              v-for="(activity, i) in user.challenges[selectedChallenge]
                .challenge.activity"
              v-bind:key="i"
              :class="
                selectedDay == i
                  ? 'challengeActivitySelectedMobile'
                  : 'challengeActivityMobile'
              "
              @click="selectedDay = i"
            >
              <md-icon
                v-if="
                  user.challenges[selectedChallenge].activitiesCompleted <= i
                "
                style="margin: 0; margin-right: 5px"
                >check_box_outline_blank</md-icon
              >
              <md-icon v-else style="margin: 0; margin-right: 5px"
                >check_box</md-icon
              >
              Day {{ i + 1 }} - {{ activity.title }}
            </div>
          </div>
          <div class="challengeTextMobile" v-if="selectedDay == 999">
            <vue-markdown>
              {{ user.challenges[selectedChallenge].challenge.text }}
            </vue-markdown>
          </div>
          <div class="challengeTextMobile" v-else>
            <div v-if="user.challenges[selectedChallenge]">
              <div
                v-bind:key="i"
                v-for="(a, i) in user.challenges[selectedChallenge].challenge
                  .activity"
              >
                <vue-markdown v-if="i === selectedDay">{{
                  user.challenges[selectedChallenge].challenge.activity[
                    selectedDay
                  ].text
                }}</vue-markdown>
              </div>
            </div>
          </div>
          <div class="author">
            <div class="flexRow">
              <img
                :src="user.challenges[selectedChallenge].challenge.author.img"
                v-if="user.challenges[selectedChallenge].challenge.author.img"
              />
              <div class="authorName">
                Author:<br />{{
                  user.challenges[selectedChallenge].challenge.author.name
                }}
              </div>
            </div>
            <div
              class="academicDescription"
              v-if="
                user.challenges[selectedChallenge].challenge.author
                  .academicDescription
              "
            >
              {{
                user.challenges[selectedChallenge].challenge.author
                  .academicDescription
              }}
            </div>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="challengePopUp = false"
          style="font-size: 1.3em; margin: 0 20px 20px 0"
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <span class="md-display-1 welcome">
      <p>
        <b> {{ getContent(72) }}, {{ user.name }}! </b>
      </p>
    </span>
    <div
      v-bind:class="{ flexRow: !myWidth, flexColumn: myWidth }"
      style="
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-bottom: 5%;
        min-height: 880px;
      "
      v-bind:style="{ height: givenHeight }"
    >
      <div
        class="flexColumn"
        style="justify-content: stretch; flex-wrap: nowrap"
        v-bind:class="{ widthMobile: myWidth, widthDesktop: !myWidth }"
      >
        <div @click="goToChat()">
          <md-card
            class="card chatCard"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span class="chatNotice" style="text-transform: uppercase"
              >Click here to enter</span
            >
            <div v-bind:class="{ title: !myWidth, titleMobile: myWidth }">
              {{ getContent(77) }}
            </div>
            <!--<md-card-actions
            style="
              flex-direction: row;
              justify-content: space-evenly;
              margin-top: 5%;
            "
          >
            <md-button
              class="md-raised md-primary"
              @click="goToChat()"
              v-if="user.answers && user.answers !== []"
            >
              {{ getContent(79) }}
            </md-button>
          </md-card-actions>-->
          </md-card>
        </div>

        <md-card class="card">
          <div v-bind:class="{ title: !myWidth, titleMobile: myWidth }">
            {{ getContent(73) }}
          </div>
          <br />
          <div class="md-body-3" style="margin-left: 1em">
            <div class="partners" style="display: flex; flex-direction: column">
              <span style="font-weight: bold">{{ getContent(75) }}</span>
              <div class="partnerNames">
                <span v-for="(partner, index) in user.partners" :key="index">
                  {{ partner.name }}
                </span>
              </div>
            </div>
            <!--<table v-if="!myWidth">
              <tr>
                <td>
                  {{ getContent(75) }}
                </td>
                <td
                    v-html="
                    user.partners.map((partner) => partner.name).join(' | ')
                  "
                ></td>
              </tr>
            </table>
            <table v-else>
              <tr>
                <td>
                  {{ getContent(75) }}
                </td>
              </tr>
              <tr>
                <td
                    v-html="
                    user.partners.map((partner) => partner.name).join(' | ')
                  "
                ></td>
              </tr>
            </table>-->
          </div>
          <md-card-actions
            style="justify-content: flex-start; flex-direction: row"
            v-if="!myWidth"
          >
            <md-button class="md-raised" @click="signOut()">
              {{ getContent(1362) }}
            </md-button>
            <br />
            <md-button class="md-raised" @click="passwordChangerDialog = true">
              {{ getContent(76) }}
            </md-button>
            <br />
            <md-button class="md-raised" @click="deleteMePopup = true">
              {{ getContent(81) }}
            </md-button>
          </md-card-actions>
          <md-card-actions
            style="
              justify-content: space-evenly;
              flex-direction: column;
              align-items: center;
            "
            v-else
          >
            <md-button class="md-raised" @click="signOut()">
              {{ getContent(1362) }}
            </md-button>
            <br />
            <md-button class="md-raised" @click="passwordChangerDialog = true">
              {{ getContent(76) }}
            </md-button>
            <br />
            <md-button class="md-raised" @click="deleteMePopup = true">
              {{ getContent(81) }}
            </md-button>
          </md-card-actions>
        </md-card>
        <md-card class="card" style="margin-bottom: 0; overflow: hidden">
          <div v-bind:class="{ title: !myWidth, titleMobile: myWidth }">
            Saved
          </div>
          <div style="overflow-y: auto; height: 90%">
            <p v-if="user.saved.length == 0">
              You have not saved a self support resource yet, go to the
              <a style="color: #005c53" @click="link({ name: 'Self Support' })"
                >self support page</a
              >
              to save a resource.
            </p>
            <div
              class="innerCard"
              v-for="article in user.saved"
              v-bind:key="article"
              @click="linkArticle(article._id)"
            >
              <div class="innerTitle">{{ article.title }}</div>
              <div class="innerText">
                {{ article.subheader }}
              </div>
            </div>
          </div>
        </md-card>
      </div>
      <md-card
        class="card"
        style="margin-bottom: 0"
        v-bind:class="{ widthDesktop: !myWidth }"
      >
        <div v-bind:class="{ title: !myWidth, titleMobile: myWidth }">News</div>
        <div style="overflow-y: auto; height: 90%">
          <div
            class="innerCard"
            v-for="article in news"
            v-bind:key="article"
            @click="linkArticle(article._id)"
          >
            <div class="innerTitle">{{ article.title }}</div>
            <div class="innerImage">
              <img :src="article.image" />
            </div>
            <div class="innerText">
              {{ article.subheader }}
            </div>
          </div>
        </div>
      </md-card>
      <md-card
        class="card"
        style="margin-bottom: 0"
        v-bind:class="{ widthDesktop: !myWidth }"
      >
        <md-card-content>
          <div v-bind:class="{ title: !myWidth, titleMobile: myWidth }">
            Challenge Calendar
          </div>
          <div class="subtitle">
            <span
              v-bind:class="{ highlighted: stateCalendar }"
              @click="stateCalendar = true"
              >upcoming</span
            ><span> | </span
            ><span
              v-bind:class="{ highlighted: !stateCalendar }"
              @click="stateCalendar = false"
              >completed</span
            >
          </div>
          <p v-if="user.challenges.length == 0">
            This is where you can find your challenges. Once you add your first
            one, it will appear here!
          </p>
          <div v-if="stateCalendar" style="overflow-y: auto; height: 75vh">
            <div v-for="(challenge, x) in user.challenges" v-bind:key="x">
              <div v-if="challenge.challenge">
                <div
                  v-for="(activity, i) in challenge.challenge.activity"
                  v-bind:key="i"
                >
                  <div
                    class="innerCard"
                    v-if="challenge.activitiesCompleted == i"
                    @click="
                      (selectedChallenge = x),
                        (selectedDay = i),
                        (challengePopUp = true)
                    "
                  >
                    <div class="innerTitle">
                      {{ challenge.challenge.title }}
                    </div>
                    <div class="innerSubtitle">
                      Day {{ i + 1 }}:
                      {{ todayDate(i, challenge.activitiesCompleted) }}
                    </div>
                    <div class="innerText">
                      {{
                        activity.text.split(" ").slice(0, 20).join(" ") + "..."
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!stateCalendar" style="overflow-y: auto; height: 75vh">
            <div v-for="(challenge, x) in user.challenges" v-bind:key="x">
              <div v-if="challenge.challenge">
                <div
                  v-for="(activity, i) in challenge.challenge.activity"
                  v-bind:key="i"
                >
                  <div
                    class="innerCard"
                    v-if="challenge.activitiesCompleted > i"
                    @click="
                      (selectedChallenge = x),
                        (selectedDay = i),
                        (challengePopUp = true)
                    "
                  >
                    <div class="innerTitle">
                      {{ challenge.challenge.title }}
                    </div>
                    <div class="innerSubtitle">Day {{ i + 1 }}: COMPLETED</div>
                    <div class="innerText">
                      {{
                        activity.text.split(" ").slice(0, 20).join(" ") + "..."
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <md-dialog :md-active.sync="passwordChangerDialog">
      <md-dialog-title>{{ getContent(1100) }}</md-dialog-title>
      <md-dialog-content>
        <div class="md-dialog-content">
          <div class="passwordChangeDiv">
            {{ getContent(1101) }}
            <md-field>
              <label>{{ getContent(1102) }}</label>
              <md-input v-model="passwordChange" type="password"></md-input>
            </md-field>

            <md-field>
              <label>{{ getContent(1103) }}</label>
              <md-input v-model="passwordChange2" type="password"></md-input>
            </md-field>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="updatePassword()">{{
          getContent(1088)
        }}</md-button>
        <md-button class="md-accent" @click="passwordChangerDialog = false">{{
          getContent(1087)
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Desktop -->
    <div v-if="!myWidth">
      <md-dialog :md-active.sync="deleteMePopup">
        <md-dialog-title class="md-accent">{{
          getContent(1254)
        }}</md-dialog-title>
        <md-dialog-content>
          <div class="md-dialog-content">
            <div class="passwordChangeDiv">
              {{ getContent(1255) }}
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="deleteMePopup = false">{{
            getContent(1256)
          }}</md-button>
          <md-button class="md-accent" @click="deleteMe()">{{
            getContent(1257)
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>

    <!-- Mobile -->
    <div v-if="myWidth">
      <md-dialog :md-active.sync="deleteMePopup">
        <md-dialog-title class="md-accent">{{
          getContent(1254)
        }}</md-dialog-title>
        <md-dialog-content>
          <div class="passwordChangeDiv">
            {{ getContent(1255) }}
          </div>
        </md-dialog-content>
        <md-dialog-actions class="mobile">
          <md-button class="md-primary" @click="deleteMePopup = false">{{
            getContent(1256)
          }}</md-button>
          <md-button class="md-accent" style="margin: 0" @click="deleteMe()">{{
            getContent(1257)
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>

    <md-dialog :md-active.sync="findMeABuddy">
      <md-dialog-title>{{ getContent(1258) }}</md-dialog-title>
      <md-dialog-content>
        <div class="passwordChangeDiv">
          {{ findMeABuddyText }}
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="findMeABuddy = false">{{
          getContent(1087)
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import { loadData } from "@/utils";
import VueMarkdown from "@adapttive/vue-markdown";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      user: {
        partners: [],
      },
      passwordChangerDialog: false,
      findMeABuddy: false,
      findMeABuddyText: "",
      deleteMePopup: false,
      noBuddie: false,
      challengePopUp: false,
      selectedChallenge: 0,
      selectedDay: 0,
      stateCalendar: true,
      givenHeight: "100vh",
      isOpen: false,
      news: [],

      passwordChange: "",
      passwordChange2: "",
      userId: store.state.accessToken,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      text: {},
      myWidth: screen.height / screen.width > 1,
    };
  },
  methods: {
    // link: function (l) {
    //   this.$router.push(l);
    // },
    link(to) {
      this.$router
        .push(to)
        .catch((err) => console.warn(`Seems we are already here: ${err}`));
    },
    activityCompleted: async function () {
      var x = this.user.challenges[this.selectedChallenge].activitiesCompleted;
      x++;
      this.user = (
        await axiosPost("/users/challenge/set/activities", {
          activitiesCompleted: x,
          challengeId: this.user.challenges[this.selectedChallenge]._id,
          userId: this.user._id,
        })
      ).data;
      console.log(this.user);
    },
    restartChallenge: async function () {
      this.user = (
        await axiosPost("/users/challenge/set/activities", {
          activitiesCompleted: 0,
          challengeId: this.user.challenges[this.selectedChallenge]._id,
          userId: this.user._id,
        })
      ).data;
      console.log(this.user);
    },
    ...mapActions(["logout"]),
    loadData() {
      return axiosGet("/users/id/" + this.userId)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    getContent: function (key) {
      return this.text[key];
    },
    findBuddy: function () {
      const me = this;
      axiosGet("/matching/" + this.user._id).then((res) => {
        if (res.data.buddies.lenght > 0)
          axiosPost("/matching/", {
            userId: res.data.user._id,
            buddyId: res.data.buddies[0]._id,
          })
            .then(
              () =>
                async function () {
                  me.findMeABuddyText =
                    "We found you a buddy! He was added to your profile.";
                  me.findMeABuddy = true;
                  me.user = await this.loadData();
                }
            )
            .catch((err) => console.warn(err));
        else {
          me.findMeABuddyText =
            "We could not find a buddy for you, yet. Please try later again!";
          me.findMeABuddy = true;
        }
      });
    },
    signOut: function () {
      this.logout();
    },
    updatePassword: function () {
      const me = this;
      if (this.passwordChange !== this.passwordChange2)
        alert(this.getContent(1089));
      else {
        axiosPatch("/users/password/change", {
          password: this.passwordChange,
          userId: this.userId,
        })
          .then(function () {
            alert(me.getContent(1098));
          })
          .catch(function (error) {
            alert(`${me.getContent(1098)} ${error}`);
            console.error(error);
          });
        this.passwordChangerDialog = false;
      }
    },
    deleteMe: async function () {
      await axiosDelete("/users/" + this.user._id);
      this.logout();
    },
    goToChat: function () {
      if (!this.user.answers || !(this.user.answers !== [])) {
        return;
      }
      if (this.user.partners.length > 0) {
        this.$router.push("chat");
      } else {
        this.noBuddie = true;
      }
    },
    linkArticle: function (a) {
      console.log(a);
      this.link({ name: "Article", params: { name: a } });
    },
    todayDate: function (i, completed) {
      const date = new Date();
      date.setDate(date.getDate() + (i - completed));
      return (
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      );
    },
  },
  async mounted() {
    this.user = await this.loadData();
    this.news = (await axiosGet("/selfSupport/content/published")).data;
    this.news.sort(function (a, b) {
      if (a.created < b.created) return 1;
      return -1;
    });
    this.news.splice(3);
  },
  async created() {
    this.text = await loadData("Dashboard", this.myLanguage);
    if (this.myWidth) this.givenHeight = "auto";
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.content {
  margin: 1em 2em 0 2em;
  text-align: left;
  color: #5f93bc;
  font-family: "DM Sans", sans-serif;
  background-color: #f4faff;
}
.md-dialog-content,
.md-accent {
  font-family: "DM Sans", sans-serif;
}
.welcome {
  color: #5f93bc;
}
.card {
  padding: 1.5em;
  margin: 1em;
  border: 10px var(--c4) solid;
  border-radius: 20px 20px 20px 20px;
}
.flexRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.delete {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 1em 1em 0;
}
.passwordChangeDiv {
  margin: 20px;
}
.md-card-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
}
.card.chatCard {
  transition: background 0.2s ease;
  cursor: pointer;
  font-weight: bold;
  background: #d9e9f7;
}
.card.chatCard:hover {
  background: #fff;
}
.partnerNames > span {
  padding: 0;
  margin: 0;
}
.partnerNames > span::after {
  content: ", ";
}
.partnerNames > span:last-child::after {
  content: "";
}
.mobile {
  flex-direction: column;
  align-items: flex-start;
}
.title,
.titleMobile {
  font-size: 40px;
  line-height: 40px;
  color: var(--c4);
  text-transform: uppercase;
}
.titleMobile {
  font-size: 30px;
  line-height: 30px;
}
.subtitle {
  font-size: 20px;
  line-height: 20px;
  color: var(--c4);
  text-transform: uppercase;
  cursor: pointer;
  padding-bottom: 2vh;
  padding-top: 2vh;
}
.innerCard {
  padding: 1.5em;
  margin: 1em;
  border: 5px #99999950 solid;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
}
.innerCard .innerTitle {
  color: #000000;
  font-weight: bold;
}
.innerCard .innerSubtitle {
  color: #999999;
}
.innerCard .innerText {
  color: #000000;
}
.innerCard .innerImage {
  color: #000000;
}
.challengeHeader,
.challengeHeaderMobile {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}
.challengeHeaderMobile {
  flex-direction: column;
}
.challengeButton,
.challengeButtonRestart,
.challengeButtonCompleted {
  background: #ffffff;
  border: 3px #00000020 solid;
  border-radius: 10px 10px 10px 10px;
  font-size: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  margin: 20px 20px 0 0;
}
.challengeButton:hover,
.challengeButtonRestart:hover {
  transform: scale(1.1);
  border: 3px #00000070 solid;
}
.challengeTitle {
  font-size: 40px;
  line-height: 40px;
  color: var(--c3);
  text-transform: uppercase;
  font-family: var(--articleh1);
}
.challengeWrapper,
.challengeWrapperMobile {
  width: 50%;
  padding-top: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.challengeWrapperMobile {
  width: 100%;
}
.challengeActivity,
.challengeActivitySelected,
.challengeActivityMobile,
.challengeActivitySelectedMobile {
  display: flex;
  flex-direction: row;
  padding-left: 20%;
  padding-bottom: 20px;
  color: var(--c4);
  font-size: 1.5em;
  line-height: 1.5em;
  opacity: 0.5;
  cursor: pointer;
  align-items: center;
  font-family: var(--articleText);
}
.challengeActivityMobile,
.challengeActivitySelectedMobile {
  padding-left: 5%;
}
.challengeActivitySelectedMobile {
  opacity: 0.99;
}
.challengeActivitySelected {
  opacity: 0.99;
  /* font-weight: bold; */
}
.challengeText,
.challengeTextMobile {
  width: 50%;
  padding: 20px 50px 20px 50px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: var(--articleText);
}
.challengeTextMobile {
  width: 100%;
  padding: 20px 0 20px 0;
}
.highlighted {
  text-decoration: underline;
}
.widthMobile {
  width: 100%;
}
.widthDesktop {
  width: 33%;
}
.challengeImage {
  height: 120px;
  width: 100%;
  background-size: 100% 100%;
  font-size: 40px;
  line-height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--articleh1);
  background-image: url("https://media.kaiser.fyi/mindsupport/1200/ChallengesBanner.png");
}
.menu {
  font-size: 1.6vw;
  color: #000000;
  margin-right: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.author {
  margin-top: 20px;
  font-size: 1.5em;
  text-align: left;
}
.author .flexRow {
  flex-wrap: nowrap;
  align-items: center;
}
.author img {
  box-sizing: border-box;
  width: 50%;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid gray;
  box-shadow: 0px 0px 10px #ccc;
  max-width: 200px;
  max-height: 200px;
}
.authorName {
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  box-sizing: border-box;
  border-left: 1px dashed #ccc;
  margin-left: 15px;
  height: 50%;
}
.author .academicDescription {
  font-size: 0.75em;
  padding: 5px;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
}
</style>
